<template>
  <!-- En-tête -->
  <!-- <span class = "d-block p-2 bg-primary"></span> -->
  <entete></entete>
  <!-- <span class="d-block p-2 bg-dark"></span> -->
  <main>
    <form class="container">
      <div class="row-inputs">
        <div class="row row-label">
          <label for="log_log">Identifiant</label>
          <label for="pwd_log">Mot de passe</label>
        </div>
        <div class="column md-row row-form">
          <input
            type="text"
            class="form-control fs21"
            placeholder="Identifiant"
            v-model="log_log"
            @keyup.enter="focusSuivant('pwd')"
            v-focus
            id="log_log"
          />
          <input
            type="password"
            class="form-control"
            placeholder="Mot de passe"
            v-model="pwd_log"
            id="pwd_log"
            ref="pwd"
            @keyup.enter="focusSuivant('connexion')"
          />
          <button
            type="button"
            id="connexion"
            ref="connexion"
            class="btn btn-plain btn-connexion"
            @keyup.enter="VerifierLogin()"
            @click="VerifierLogin()"
          >
            Se connecter
          </button>
        </div>
        <div class="md-row row-form-create">
          <button type="button" id="creercompte" class="btn btn-outline btn-account">
            Mot de passe oublié
          </button>
        </div>
        <!-- ERREUR DE SAISIE - AFFICHAGE DU MESSAGE D'ERREUR POUR L'UTILISATEUR -->
        <div class="alert alert-danger" v-if="retourError">
          Identifiant ou mot de passe incorrect
        </div>
      </div>
      <div class="column md-row row-textuel">
        <div class="col-intro col-100 col-md-60">
          <h1>Un webshop conçu pour les pros</h1>
          <p>
            Qui vous permet de trouver rapidement la bonne pièce au bon prix pour votre
            réparation.<br />Plus d'éfficacité, plus de services, plus de garanties pour
            gérer votre activité avec sérénité et professionnalisme.
          </p>
        </div>
        <div class="col-video col-100 col-md-50">
          <figure>
            <img
              src="../assets/jpg/thumbnail_video_Close_Resize.jpg"
              class="img-featured"
            />
          </figure>
          <a class="link-video" href="/" title="En savoir plus sur le Webshop"
            >Voir la vidéo de présentation <i class="icon icon-lecture"></i
          ></a>
        </div>
      </div>
    </form>
  </main>
  <competence></competence>
</template>

<script>
import { useRouter } from "vue-router";
import entete from "@/components/comp.entete.vue";
import competence from "@/components/comp.competence.vue";
const router = useRouter();

const gtagConfig = {
  "delestrez.autoref.fr": "G-4HKBH7N6BG",
  "durandservices.autoref.fr": "G-YYYYYYYYYY",
  "guiproman.autoref.fr": "G-ZZZZZZZZZZ",
  "ved-70.autoref.fr": "G-AAAAAAAAAA",
  "aisnedieselservices.autoref.fr": "G-BBBBBBBBBB",
  "marceul.autoref.fr": "G-CCCCCCCCCC",
};

export default {
  name: "index",
  created() {
    this.$store.dispatch("resetState");
    this.loadGtag();
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  components: {
    entete,
    competence,
  },
  data() {
    return {
      log_log: "",
      pwd_log: "",
      retourError: false,
      deferredPrompt: null,
      showInstallPopup: false,
      isIos: false,
      isInStandaloneMode: false,
      isDesktop: false,
    };
  },
  mounted() {
    // Ajoute la classe "connexion" du body quand le composant se monte
    document.body.classList.add("connexion");
    window.addEventListener("beforeunload", (event) => {
      var refresh = this.$route.name;
      this.$router.push({ name: refresh });
    });
  },
  methods: {
    loadGtag() {
      const hostname = window.location.hostname;
      const gtagId = gtagConfig[hostname] || null;
      if (gtagId) {
        const existingScript = Array.from(
          document.head.getElementsByTagName("script")
        ).find((script) =>
          script.src.includes(`googletagmanager.com/gtag/js?id=${gtagId}`)
        );
        if (!existingScript) {
          const script = document.createElement("script");
          script.async = true;
          script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
          document.head.appendChild(script);
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", gtagId);
          this.$sessionStorage.set("gtag", gtagId);
        }
      } else {
        console.warn(`Aucun gtag configuré pour ${hostname}`);
      }
    },
    focusSuivant(nextRef) {
      this.$refs[nextRef].focus();
    },
    VerifierLogin: function () {
      var prog = "./src/req/VerifierLogin.php";
      // envoie des données dans le POSTS
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          log_log: this.log_log,
          pwd_log: this.pwd_log,
        }),
      };
      fetch(prog, requestOptions)
        .then((response) => {
          // Vérifier si la réponse est correcte (status HTTP 200)
          if (!response.ok) {
            throw new Error("Erreur serveur: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          if (data.retour == "OK") {
            this.$sessionStorage.set("log_log", this.log_log),
              this.$sessionStorage.set("pwd_log", this.pwd_log),
              this.$sessionStorage.set("instance", data.ins_adh),
              this.$sessionStorage.set("id_adherent", data.id_adherent),
              this.$sessionStorage.set("id_magasin", data.id_magasin),
              this.$sessionStorage.set("id_fiadd", data.id_fiadd),
              this.$sessionStorage.set("id_fiaddc", data.id_fiaddc),
              this.$sessionStorage.set("id_fiaddgar", data.id_fiaddgar),
              this.$sessionStorage.set("etb_mag", data.etb_mag),
              this.$sessionStorage.set("vil_mag", data.vil_mag),
              this.$sessionStorage.set("ad1_mag", data.ad1_mag),
              this.$sessionStorage.set("ad2_mag", data.ad2_mag),
              this.$sessionStorage.set("sir_mag", data.sir_mag),
              this.$sessionStorage.set("tel_mag", data.tel_mag),
              this.$sessionStorage.set("id_cligadi", data.id_cligadi),
              // MISE EN MEMOIRE DANS LE STORE
              this.$store.commit(
                "setgLinkingTargetTypeDefaut",
                data.linkingTargetTypeDefaut
              );
            this.$store.commit("setgCod_metDefaut", data.cod_metDefaut);
            this.$store.commit("setgGpi_adh", data.gpi_adh);
            this.$store.commit("setgPic_adh", data.pic_adh);
            this.$store.commit("setgGart_adh", data.gart_adh);
            this.$store.commit("setgTopHeader", data.gTopHeader);
            this.$store.commit("setgPla_adh", data.pla_adh);
            // Créer une session si elle n'existe pas encore
            const gSession =
              "session_" + Date.now() + "_" + Math.random().toString(36).substr(2, 9);
            this.$store.commit("setgSession", gSession); // Session our le panier
            // Chargement du style si PL ou VL
            if (data.cod_metDefaut == "PL") {
              document.body.classList.add("pl");
              this.$store.commit("gPortailDefaut", "portailPL");
              this.$router.push({ name: "portailPL" });
            } else {
              this.$store.commit("gPortailDefaut", "portailVL");
              this.$router.push({ name: "portailVL" });
            }

            //this.$router.push({ name: "portail", query: { id_filogin: 0 } });
          } else {
            //ERREUR DE SAISIE
            this.retourError = true;
            this.log_log = "";
            this.pwd_log = "";
            document.getElementById("log_log").focus();
          }
        });
    },
  },
};
</script>
<style scoped>
.install-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.ios-help {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.icon {
  width: 20px;
  vertical-align: middle;
}
</style>
